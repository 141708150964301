<template>
  <div>
    <v-btn
      color="secondary"
      @click="openDialog"
    >
      <v-icon>mdi-delete</v-icon>
      <span>{{ $t("message.delete_furniture_list") }}</span>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-alert
            outlined
            shaped
            prominent
            text
            dense
            type="warning"
          >
            {{ $t("message.confirmation") }}
          </v-alert>
        </v-card-title>
        <v-card-text>
          {{ $t("message.confirmation_text_furniture_list") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"

            @click="clearList"
          >
            {{ $t("message.yes") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="dialog = false"
          >
            {{ $t("message.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DeleteFurnitureList',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
    },
    methods: {
      openDialog () {
        this.dialog = true
      },
      clearList () {
        this.addedItems = [] // addedItems配列を空にする
        localStorage.removeItem('furnitureList') // ローカルストレージからデータを削除する
        location.reload() // ページを再読み込みする
      },
    },
  }
</script>
