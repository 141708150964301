// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'message.home',
      icon: 'mdi-home',
      to: '/',
    },
    {
      title: 'message.furnishings',
      icon: 'mdi-table-furniture',
      to: '/components/furnishings/',
    },
    {
      title: 'message.popular-ff14-housing-tweets',
      icon: 'mdi-star-shooting-outline',
      to: '/components/popular-ff14-housing-tweets/',
    },
    {
      title: 'message.ff14-housing-tweet-with-over-1000-likes',
      icon: 'mdi-crown',
      to: '/components/ff14-housing-tweet-with-over-1000-likes/',
    },
    {
      title: 'message.howsinger_directory',
      icon: 'mdi-account',
      to: '/components/housingers',
    },
    {
      title: 'message.concept_tweets',
      icon: 'mdi-table-furniture',
      to: '/components/concept-new',
    },
    {
      title: 'message.movie',
      icon: 'mdi-youtube',
      to: '/components/movie',
    },
    {
      title: 'message.artworks',
      icon: 'mdi-home-outline',
      to: '/components/artworks',
    },
    {
      title: 'message.favorite',
      icon: 'mdi-heart',
      to: '/components/favorite/',
    },
    // {
    //   title: 'message.event',
    //   icon: 'mdi-calendar-star',
    //   to: '/components/event',
    // },
    {
      title: 'message.eorzea_time',
      icon: 'mdi-clock-time-three-outline',
      to: '/components/eorzea-time',
    },
    // {
    //   title: 'message.glamour_recipe',
    //   icon: 'mdi-face-woman-shimmer',
    //   to: '/components/glamour-recipe',
    // },
    // {
    //   title: 'message.glamour_recipe_maker',
    //   icon: 'mdi-face-recognition',
    //   to: '/components/glamour-recipe-maker',
    // },

    // {
    //   title: 'message.featured_tweets',
    //   icon: 'mdi-star-shooting-outline',
    //   to: '/components/high-profile/',
    // }, // {
    //   title: 'Regular Tables',
    //   icon: 'mdi-clipboard-outline',
    //   to: '/tables/regular/',
    // },
    // {
    //   title: 'Typography',
    //   icon: 'mdi-format-font',
    //   to: '/components/typography/',
    // },
    // {
    //   title: 'Icons',
    //   icon: 'mdi-chart-bubble',
    //   to: '/components/icons/',
    // },
    // {
    //   title: 'Google Maps',
    //   icon: 'mdi-map-marker',
    //   to: '/maps/google/',
    // },
    // {
    //   title: 'Notifications',
    //   icon: 'mdi-bell',
    //   to: '/components/notifications/',
    // },
    // {
    //   title: 'message.hall_of_fame_tweets',
    //   icon: 'mdi-crown',
    //   to: '/components/hall-of-fame/',
    // },
    // {
    //   title: 'message.commission',
    //   icon: 'mdi-home-account',
    //   to: '/components/commission/tab-1',
    // },
    // {
    //   title: 'message.technique_tweets',
    //   icon: 'mdi-arm-flex',
    //   to: '/components/technique/',
    // },
    // {
    //   title: 'じわ伸びツイート',
    //   icon: 'mdi-elevation-rise',
    //   to: '/components/gradually/',
    // },
    // {
    //   title: 'message.tweet_registration_tool',
    //   icon: 'mdi-tools',
    //   to: '/components/tool/tab-1',
    // },
    // {
    //   title: 'message.aetheryte',
    //   icon: 'mdi-rhombus-split-outline',
    //   to: '/components/aetheryte/',
    // },
    {
      title: 'message.about_this_site',
      icon: 'mdi-information-outline',
      to: '/components/help/',
    },
    {
      title: 'message.q&a',
      icon: 'mdi-help-circle-outline',
      to: '/components/q&a/',
    },
    {
      title: 'message.policy',
      icon: 'mdi-shield-check',
      to: '/components/policy/',
    },
    {
      title: 'message.supporter',
      icon: 'mdi-account-group',
      to: '/components/supporter/',
    },
      // {
      //   title: '管理人',
      //   icon: 'mdi-twitter',
      //   to: '/components/admin/',
      // },
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {
  // page (state) {
  //   return state.page
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
