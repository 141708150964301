<template>
  <div>
    <a
      href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=3580315&pid=889431184"
      rel="nofollow"
      ><v-img
        max-width="20vw"
        min-width="300px"
        contain
        src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=3580315&pid=889431184"
        border="0"
    /></a>
  </div>
</template>

<script>
  export default {
    name: 'EstoreCard',
  };
</script>
