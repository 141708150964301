<template>
  <v-fade-transition mode="out-in">
    <!-- <li><router-link to="/routing">Rounting Test</router-link></li> -->
    <router-view />
  </v-fade-transition>
</template>

<script>
  // Styles
  import '@/styles/overrides.sass';
  import axios from 'axios';
  export default {
    name: 'App',
    metaInfo: {
      htmlAttrs: { lang: 'ja' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'keywords',
          content:
            'ハウジング,ハウジングエデン,ff14,ff14 ハウジング,ffxiv housing,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: 'ff14housingeden' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?2',
        },
      ],
    },
    created: async function () {
      // 日付をYYYY-MM-DDの書式で返すメソッド
      function formatDate(dt) {
        var y = dt.getFullYear();
        var m = ('00' + (dt.getMonth() + 1)).slice(-2);
        var d = ('00' + dt.getDate()).slice(-2);
        return y + '-' + m + '-' + d;
      }
      this.targetDate = new Date();
      this.targetDate = formatDate(
        new Date(
          this.targetDate.getFullYear(),
          this.targetDate.getMonth(),
          this.targetDate.getDate() - 2
        )
      );
      // console.log(this.targetDate)
      await axios
        .get(`../../../eden_ver.txt?timestamp=${new Date().getTime()}`)
        .then((res) => {
          console.log(res.data);
        });
    },
  };
</script>

<!-- <style lang="scss">
.v-application{
    // font-family: "Noto Sans JP" !important;
    // font-family: "Hiragino Kaku Gothic Pro","メイリオ", sans-serif!important;
    // font-family: "Noto Sans JP", "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif !important;
    font-family: "Noto Sans JP", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
    // font-family: monospace !important;
}
</style> -->

<style>
  body,
  .v-application {
    font-family: 'M PLUS Rounded 1c', sans-serif !important;
    font-weight: 700;
    font-family: 'Roboto Flex', sans-serif !important;
  }
</style>
