// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'
import VueGtag from 'vue-gtag'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
        sessionStorage.setItem('positionY', savedPosition.y)
        return savedPosition
    } else {
        sessionStorage.setItem('positionY', 0)
        return { x: 0, y: 0 }
    }
  },
  routes: [
    layout('Default', [
      route('Home'), // name, component, path

      // Pages

      // Components
      route('Favorite', null, 'components/favorite'),
      route('PopularFF14HousingTweets', null, 'components/popular-ff14-housing-tweets'),
      route('FF14HousingTweetWithOver1000Likes', null, 'components/ff14-housing-tweet-with-over-1000-likes'),
      route('Housingers', null, 'components/housingers'), // name, component, path
      route('Movie', null, 'components/movie'), // name, component, path
      route('Artworks', null, 'components/artworks/:tabid?'), // name, component, path
      route('Event', null, 'components/event'), // name, component, path
      route('EventPhoto', null, 'components/event-photo'), // name, component, path
      route('HighProfile', null, 'components/high-profile'), // name, component, path
      route('HallOfFame', null, 'components/hall-of-fame'),
      route('ConceptNew', null, 'components/concept-new'),
      route('ConceptTool', null, 'components/concept-tool'),
      route('EorzeaTime', null, 'components/eorzea-time'),
      route('GlamourRecipe', null, 'components/glamour-recipe'),
      route('GlamourRecipeMaker', null, 'components/glamour-recipe-maker'),
      route('Furnishings', null, 'components/furnishings'),
      route('Technique', null, 'components/technique'),
      // route('Gradually', null, 'components/gradually'),
      route('Help', null, 'components/help'),
      route('Q&A', null, 'components/q&a'),
      // route('Tweet', null, 'components/tweet'),
      // route('EdenTimeline', null, 'components/eden-timeline'),
      route('Artwork', null, 'components/artwork/:id/:tabid?'),
      route('Tool', null, 'components/tool/:tabid'),
      route('Aetheryte', null, 'components/aetheryte'),
      route('Policy', null, 'components/policy'),
      route('Commission', null, 'components/commission/:tabid'),
      route('Supporter', null, 'components/supporter'),
      // route('Profile', Profile, 'views/profile'),
      route('Profile', null, 'components/profile'),
      // Tables
      // route('Regular Tables', null, 'tables/regular'),

      // Maps
      // route('Google Maps', null, 'maps/google'),

    ]),
  ],
})

router.beforeEach((to, from, next) => {
  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

Vue.use(VueGtag, {
  config: { id: 'G-6GFZWSDEPP' },
}, router)

export default router
