<template>
  <v-dialog v-model="menu" max-width="500" persistent>
    <v-card>
      <!-- カラーピッカー -->
      <v-color-picker
        flat
        hide-canvas
        hide-inputs
        hide-sliders
        :value="internalColor"
        :swatches="getAllColors()"
        @input="updateColor"
      ></v-color-picker>
      <v-card-actions>
        <!-- 選択中の色と色名を動的に表示 -->
        <v-chip
          :style="{
            backgroundColor: internalColor,
            color: '#000',
            fontFamily: 'M PLUS Rounded 1c, sans-serif',
          }"
          label
          small
        >
          {{ selectedColorName || $t('message.selected_color') }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn text @click="close">close</v-btn>
      </v-card-actions>

      <!-- 中分類用の v-expansion-panels -->
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(category, index) in localizedCategories"
          :key="index"
        >
          <!-- パネルヘッダーの背景色をカテゴリの最初の色に設定 -->
          <v-expansion-panel-header
            :style="{
              backgroundColor: category.colors[0].color,
              color: '#000',
              fontFamily: 'M PLUS Rounded 1c, sans-serif',
            }"
          >
            {{ category.name }}
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-list class="scrollable-list">
              <v-list-item
                v-for="(swatch, swatchIndex) in category.colors"
                :key="swatchIndex"
                @click="selectColor(swatch)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <div
                      :style="{
                        color: '#000',
                        fontFamily: 'M PLUS Rounded 1c, sans-serif',
                      }"
                    >
                      {{ swatch.name }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar :color="swatch.color"></v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ColorPickerDialog',
    data() {
      return {
        menu: false,
        internalColor: '', // 選択した色を格納するプロパティ
        selectedColorName: '', // 選択中の色名を格納するプロパティ
        currentIndex: null, // 選択中のインデックスを保持
        // 中分類用のカテゴリ
        categories: [
          {
            name: { ja: '白系', en: 'White Colors' },
            colors: [
              {
                name: { ja: 'スノウホワイト', en: 'Snow White' },
                color: '#E4DFD0',
              },
              {
                name: { ja: 'アッシュグレイ', en: 'Ash Gray' },
                color: '#ACA8A2',
              },
              {
                name: { ja: 'グゥーブーグレイ', en: 'Goobbue Gray' },
                color: '#898784',
              },
              {
                name: { ja: 'スレートグレイ', en: 'Slate Gray' },
                color: '#656565',
              },
              {
                name: { ja: 'チャコールグレイ', en: 'Charcoal Gray' },
                color: '#484742',
              },
              {
                name: { ja: 'スートブラック', en: 'Soot Black' },
                color: '#2b2923',
              },
            ],
          },
          {
            name: { ja: '赤系', en: 'Red Colors' },
            colors: [
              {
                name: { ja: 'ローズピンク', en: 'Rose Pink' },
                color: '#E69F96',
              },
              {
                name: { ja: 'ライラックパープル', en: 'Lilac Purple' },
                color: '#836969',
              },
              {
                name: { ja: 'ロランベリーレッド', en: 'Rolanberry Red' },
                color: '#5B1729',
              },
              {
                name: { ja: 'ダラガブレッド', en: 'Dalamud Red' },
                color: '#781A1A',
              },
              {
                name: { ja: 'ラストレッド', en: 'Rust Red' },
                color: '#622207',
              },
              {
                name: { ja: 'ワインレッド', en: 'Wine Red' },
                color: '#451511',
              },
              {
                name: { ja: 'コーラルピンク', en: 'Coral Pink' },
                color: '#CC6C5E',
              },
              {
                name: { ja: 'ブラッドレッド', en: 'Blood Red' },
                color: '#913B27',
              },
              {
                name: { ja: 'サーモンピンク', en: 'Salmon Pink' },
                color: '#E4AA8A',
              },
              {
                name: { ja: 'ルビーレッド', en: 'Ruby Red' },
                color: '#E40011',
              },
              {
                name: { ja: 'チェリーピンク', en: 'Cherry Pink' },
                color: '#F5379B',
              },
            ],
          },
          {
            name: { ja: '茶系', en: 'Brown Colors' },
            colors: [
              {
                name: { ja: 'サンセットオレンジ', en: 'Sunset Orange' },
                color: '#B75C2D',
              },
              { name: { ja: 'メサレッド', en: 'Mesa Red' }, color: '#7D3906' },
              {
                name: { ja: 'バークブラウン', en: 'Bark Brown' },
                color: '#6A4B37',
              },
              {
                name: { ja: 'チョコレートブラウン', en: 'Chocolate Brown' },
                color: '#6E3D24',
              },
              {
                name: { ja: 'ラセットブラウン', en: 'Russet Brown' },
                color: '#4F2D1F',
              },
              {
                name: { ja: 'コボルドブラウン', en: 'Kobold Brown' },
                color: '#30211B',
              },
              {
                name: { ja: 'コルクブラウン', en: 'Cork Brown' },
                color: '#C99156',
              },
              {
                name: { ja: 'キキルンブラウン', en: 'Qiqirn Brown' },
                color: '#996E3F',
              },
              {
                name: { ja: 'オポオポブラウン', en: 'Opo-Opo Brown' },
                color: '#7B5C2D',
              },
              {
                name: { ja: 'アルドゴートブラウン', en: 'Aldgoat Brown' },
                color: '#A2875C',
              },
              {
                name: { ja: 'パンプキンオレンジ', en: 'Pumpkin Orange' },
                color: '#C57424',
              },
              {
                name: { ja: 'エーコンブラウン', en: 'Acorn Brown' },
                color: '#8E581B',
              },
              {
                name: { ja: 'オーチャードブラウン', en: 'Orchard Brown' },
                color: '#644216',
              },
              {
                name: { ja: 'チェスナットブラウン', en: 'Chestnut Brown' },
                color: '#3D290D',
              },
              {
                name: { ja: 'ゴブリンブラウン', en: 'Goblin Brown' },
                color: '#B9A489',
              },
              {
                name: { ja: 'シェールブラウン', en: 'Shale Brown' },
                color: '#92816C',
              },
              {
                name: { ja: 'モールブラウン', en: 'Mole Brown' },
                color: '#615245',
              },
              {
                name: { ja: 'ロームブラウン', en: 'Loam Brown' },
                color: '#3F3329',
              },
            ],
          },
          {
            name: { ja: '黄系', en: 'Yellow Colors' },
            colors: [
              {
                name: { ja: 'ボーンホワイト', en: 'Bone White' },
                color: '#EBD3A0',
              },
              {
                name: { ja: 'ウルダハンブラウン', en: 'Ul’dahn Brown' },
                color: '#B7A370',
              },
              {
                name: { ja: 'デザートイエロー', en: 'Desert Yellow' },
                color: '#DBB457',
              },
              {
                name: { ja: 'ハニーイエロー', en: 'Honey Yellow' },
                color: '#FAC62B',
              },
              {
                name: {
                  ja: 'ミリオンコーンイエロー',
                  en: 'Millioncorn Yellow',
                },
                color: '#E49E34',
              },
              {
                name: { ja: 'クァールイエロー', en: 'Coeurl Yellow' },
                color: '#BC8804',
              },
              {
                name: { ja: 'クリームイエロー', en: 'Cream Yellow' },
                color: '#F2D770',
              },
              {
                name: { ja: 'ハラタリイエロー', en: 'Halatali Yellow' },
                color: '#A58430',
              },
              {
                name: { ja: 'レーズンブラウン', en: 'Raisin Brown' },
                color: '#403311',
              },
              {
                name: { ja: 'カナリーイエロー', en: 'Canary Yellow' },
                color: '#FEF864',
              },
              {
                name: { ja: 'バニライエロー', en: 'Vanilla Yellow' },
                color: '#FBF1B4',
              },
            ],
          },
          {
            name: { ja: '緑系', en: 'Green Colors' },
            colors: [
              {
                name: { ja: 'マッドグリーン', en: 'Mud Green' },
                color: '#585230',
              },
              {
                name: { ja: 'シルフグリーン', en: 'Sylph Green' },
                color: '#BBBB8A',
              },
              {
                name: { ja: 'ライムグリーン', en: 'Lime Green' },
                color: '#ABB054',
              },
              {
                name: { ja: 'モスグリーン', en: 'Moss Green' },
                color: '#707326',
              },
              {
                name: { ja: 'メドウグリーン', en: 'Meadow Green' },
                color: '#8B9C63',
              },
              {
                name: { ja: 'オリーヴグリーン', en: 'Olive Green' },
                color: '#4B5232',
              },
              {
                name: { ja: 'マーシュグリーン', en: 'Marsh Green' },
                color: '#323621',
              },
              {
                name: { ja: 'アップルグリーン', en: 'Apple Green' },
                color: '#9BB363',
              },
              {
                name: { ja: 'サボテンダーグリーン', en: 'Cactuar Green' },
                color: '#658241',
              },
              {
                name: { ja: 'ハンターグリーン', en: 'Hunter Green' },
                color: '#284B2C',
              },
              {
                name: { ja: 'オチューグリーン', en: 'Ochu Green' },
                color: '#406339',
              },
              {
                name: { ja: 'アダマンタスグリーン', en: 'Adamantoise Green' },
                color: '#5F7558',
              },
              {
                name: { ja: 'ノフィカグリーン', en: 'Nophica Green' },
                color: '#3B4D3C',
              },
              {
                name: { ja: 'ディープウッドグリーン', en: 'Deepwood Green' },
                color: '#1E2A21',
              },
              {
                name: { ja: 'セレストグリーン', en: 'Celeste Green' },
                color: '#96BDB9',
              },
              {
                name: { ja: 'ターコイズグリーン', en: 'Turquoise Green' },
                color: '#437272',
              },
              {
                name: { ja: 'モルボルグリーン', en: 'Morbol Green' },
                color: '#1F4646',
              },
            ],
          },
          {
            name: { ja: '青系', en: 'Blue Colors' },
            colors: [
              {
                name: { ja: 'アイスブルー', en: 'Ice Blue' },
                color: '#B2C4CE',
              },
              {
                name: { ja: 'スカイブルー', en: 'Sky Blue' },
                color: '#83B0D2',
              },
              {
                name: { ja: 'シーフォグブルー', en: 'Sea Fog Blue' },
                color: '#6481A0',
              },
              {
                name: { ja: 'ピーコックブルー', en: 'Peacock Blue' },
                color: '#3B6886',
              },
              {
                name: { ja: 'ロータノブルー', en: 'Rhotano Blue' },
                color: '#1C3D54',
              },
              {
                name: { ja: 'コープスブルー', en: 'Corpse Blue' },
                color: '#8E9BAC',
              },
              {
                name: { ja: 'セルレアムブルー', en: 'Ceruleum Blue' },
                color: '#4F5766',
              },
              {
                name: { ja: 'ウォードブルー', en: 'Woad Blue' },
                color: '#2F3851',
              },
              {
                name: { ja: 'インクブルー', en: 'Ink Blue' },
                color: '#1A1F27',
              },
              {
                name: { ja: 'ラプトルブルー', en: 'Raptor Blue' },
                color: '#5B7FC0',
              },
              {
                name: { ja: 'オサードブルー', en: 'Othard Blue' },
                color: '#2F5889',
              },
              {
                name: { ja: 'ストームブルー', en: 'Storm Blue' },
                color: '#234172',
              },
              {
                name: { ja: 'ヴォイドブルー', en: 'Void Blue' },
                color: '#112944',
              },
              {
                name: { ja: 'ロイヤルブルー', en: 'Royal Blue' },
                color: '#273067',
              },
              {
                name: { ja: 'ミッドナイトブルー', en: 'Midnight Blue' },
                color: '#181937',
              },
              {
                name: { ja: 'シャドウブルー', en: 'Shadow Blue' },
                color: '#373747',
              },
              {
                name: { ja: 'アビサルブルー', en: 'Abyssal Blue' },
                color: '#312D57',
              },
              {
                name: { ja: 'ドラグーンブルー', en: 'Dragoon Blue' },
                color: '#000EA2',
              },
              {
                name: { ja: 'ターコイズブルー', en: 'Turquoise Blue' },
                color: '#04AFCD',
              },
            ],
          },
          {
            name: { ja: '紫系', en: 'Purple Colors' },
            colors: [
              {
                name: { ja: 'ラベンダーブルー', en: 'Lavender Blue' },
                color: '#877FAE',
              },
              {
                name: { ja: 'グルームパープル', en: 'Gloom Purple' },
                color: '#514560',
              },
              {
                name: { ja: 'カラントパープル', en: 'Currant Purple' },
                color: '#322C3B',
              },
              {
                name: { ja: 'アイリスパープル', en: 'Iris Purple' },
                color: '#B79EBC',
              },
              {
                name: { ja: 'グレープパープル', en: 'Grape Purple' },
                color: '#3B2A3D',
              },
              {
                name: { ja: 'ロータスピンク', en: 'Lotus Pink' },
                color: '#FECEF5',
              },
              {
                name: { ja: 'コリブリピンク', en: 'Colibri Pink' },
                color: '#DC9BCA',
              },
              {
                name: { ja: 'プラムパープル', en: 'Plum Purple' },
                color: '#79526C',
              },
              {
                name: { ja: 'リーガルパープル', en: 'Regal Purple' },
                color: '#66304E',
              },
            ],
          },
          {
            name: { ja: 'レア系', en: 'Rare Colors' },
            colors: [
              {
                name: { ja: 'ピュアホワイト', en: 'Pure White' },
                color: '#F9F8F4',
              },
              {
                name: { ja: 'ジェットブラック', en: 'Jet Black' },
                color: '#1E1E1E',
              },
              {
                name: { ja: 'パステルピンク', en: 'Pastel Pink' },
                color: '#FDC8C6',
              },
              {
                name: { ja: 'ダークレッド', en: 'Dark Red' },
                color: '#321919',
              },
              {
                name: { ja: 'ダークブラウン', en: 'Dark Brown' },
                color: '#28211C',
              },
              {
                name: { ja: 'パステルグリーン', en: 'Pastel Green' },
                color: '#BACFAA',
              },
              {
                name: { ja: 'ダークグリーン', en: 'Dark Green' },
                color: '#152C2C',
              },
              {
                name: { ja: 'パステルブルー', en: 'Pastel Blue' },
                color: '#96A4D9',
              },
              {
                name: { ja: 'ダークブルー', en: 'Dark Blue' },
                color: '#121F2D',
              },
              {
                name: { ja: 'パステルパープル', en: 'Pastel Purple' },
                color: '#BBB5DA',
              },
              {
                name: { ja: 'ダークパープル', en: 'Dark Purple' },
                color: '#232026',
              },
              {
                name: { ja: 'シャインシルバー', en: 'Shine Silver' },
                color: '#A7A7A7',
              },
              {
                name: { ja: 'シャインゴールド', en: 'Shine Gold' },
                color: '#EDE63D',
              },
              {
                name: { ja: 'メタリックレッド', en: 'Metallic Red' },
                color: '#B61D4B',
              },
              {
                name: { ja: 'メタリックオレンジ', en: 'Metallic Orange' },
                color: '#E48C2F',
              },
              {
                name: { ja: 'メタリックイエロー', en: 'Metallic Yellow' },
                color: '#F4DA46',
              },
              {
                name: { ja: 'メタリックグリーン', en: 'Metallic Green' },
                color: '#22A757',
              },
              {
                name: { ja: 'メタリックスカイブルー', en: 'Metallic Sky Blue' },
                color: '#5ED4E6',
              },
              {
                name: { ja: 'メタリックブルー', en: 'Metallic Blue' },
                color: '#5052D9',
              },
              {
                name: { ja: 'メタリックパープル', en: 'Metallic Purple' },
                color: '#A366C2',
              },
              { name: { ja: 'ガンメタル', en: 'Gunmetal' }, color: '#606068' },
              {
                name: { ja: 'パールホワイト', en: 'Pearl White' },
                color: '#E9E3DA',
              },
              {
                name: { ja: 'シャインブラス', en: 'Shine Brass' },
                color: '#E7D197',
              },
            ],
          },
        ],
      };
    },
    computed: {
      localizedCategories() {
        const currentLang = this.$i18n.locale; // 現在の言語（例: 'ja' または 'en'）

        return this.categories.map((category) => ({
          ...category,
          name: category.name[currentLang], // 現在の言語に応じたカテゴリ名を取得
          colors: category.colors.map((color) => ({
            ...color,
            name: color.name[currentLang], // 現在の言語に応じたカラー名を取得
          })),
        }));
      },
    },
    methods: {
      open(index) {
        this.menu = true;
        this.currentIndex = index; // 現在選択している色のインデックスを保存
      },
      close() {
        this.menu = false;
        // this.$emit('input', this.internalColor);
      },
      selectColor(swatch) {
        this.internalColor = swatch.color;
        this.selectedColorName = swatch.name;

        console.log('Swatch Color:', swatch.color); // デバッグ情報
        console.log('Swatch Name:', swatch.name); // デバッグ情報

        // 選択した色とインデックスを親に渡す
        this.$emit(
          'colorSelected',
          {
            color: this.internalColor,
            name: this.selectedColorName,
          },
          this.currentIndex
        ); // インデックスも一緒に渡す

        this.menu = false; // ダイアログを閉じる
      },
      updateColor(color) {
        this.internalColor = color;
        const selectedSwatch = this.categories
          .flatMap((category) => category.colors)
          .find((swatch) => swatch.color === color);
        if (selectedSwatch) {
          this.selectedColorName = selectedSwatch.name; // 色名をセット
        }
        // 選択した色を親に渡すイベントを発火
        this.$emit(
          'colorSelected',
          { color: this.internalColor, name: this.selectedColorName },
          this.currentIndex
        );
      },
      getAllColors() {
        return this.categories.flatMap((category) =>
          category.colors.map((swatch) => swatch.color)
        );
      },
    },
  };
</script>

<style scoped>
  .scrollable-list {
    max-height: 200px; /* スクロールバーが出る高さを設定 */
    overflow-y: auto; /* 縦方向のスクロールを有効化 */
  }
</style>
