import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'
import VueI18n from 'vue-i18n'
import VueYoutube from 'vue-youtube'
import vGallery from 'v-gallery'
import VueCompositionApi from '@vue/composition-api'
import { VueMasonryPlugin } from 'vue-masonry'
import Toasted from 'vue-toasted'

// Import the Auth0 configuration
import { domain, clientId, authorizationParams } from '../auth_config.json';

// Import the plugin here
import { Auth0Plugin } from './auth';

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  authorizationParams,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

const data = require('./message.json')

Vue.config.productionTip = false

sync(store, router)

Vue.use(VueMeta)
Vue.use(VueClipboard)
Vue.use(VueI18n)
Vue.use(VueYoutube)
Vue.use(vGallery)
Vue.use(VueCompositionApi)
Vue.use(VueMasonryPlugin)
Vue.use(Toasted, {
  duration: 3000, // トーストの表示時間（ミリ秒）
})

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0], // デフォルト言語はブラウザの言語
  fallbackLocale: 'ja',
  messages: data,
})

new Vue({
  i18n: i18n,
  router,
  vuetify,
  store,
  methods: {
    handleClick_changeLanguage (lang) {
      this.$i18n.locale = lang
    },
  },
  render: h => h(App),
}).$mount('#app')
